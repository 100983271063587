<template>
  <div id="App">
    <van-nav-bar
      title="订单确认"
      left-text="退出"
      left-arrow="true"
      @click-left="onLoginOut"
    >
      <template #right>
        <van-icon name="scan" size="58" @click="scan()" />
      </template>
    </van-nav-bar>
    <van-row type="flex">
      <van-col span="4"></van-col>
      <van-col span="18">
        <van-field
          :label="'会员卡号：' + hykh"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约日期：' + yyrq"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约时间：' + yysj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'场地名称：' + cdmc"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约费用：￥' + fyhj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'卡上余额：￥' + ksye"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'实际使用：' + sysj"
          label-width="100%"
          label-align="left"
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-divider style="color:red;font-size:x-large">{{ yyzt }}</van-divider>
    <van-row gutter="20" style="margin-top: 25px">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-button
          round
          block
          type="primary"
          v-show="yyzt == '预约中' ? true : false"
          @click="dosave(2)"
        >
          开始
        </van-button>
        <van-button
          round
          block
          type="danger"
          v-show="yyzt == '使用中' ? true : false"
          @click="dosave(3)"
        >
          结束
        </van-button>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-row gutter="20" style="margin-top: 20px">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-button
          round
          block
          type="info"
          v-show="yyzt == '' ? false : true"
          @click="showInfo('')"
        >
          返回
        </van-button>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-overlay :show="!finished">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      code: "",
      hykh: "",
      yyrq: "",
      yysj: "",
      sysj: "",
      cdmc: "",
      fyhj: "",
      ksye: "",
      yyzt: "",
      sys: false, // 扫一扫调用
      finished: false
    };
  },
  computed: {
    ...mapState(["userInfo", "weChatUrl"])
  },
  methods: {
    onLoginOut() {
      var that = this;
      if (that.userInfo.wechatUserCode == "") {
        that.$store.commit("setUserInfo", that.userInfoOut);
        that.$router.push("/");
      } else {
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "GetWechatLoginOut",
              Params: [that.userInfo.wechatUserCode]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              that.$store.commit("setUserInfo", that.userInfoOut);
              that.$router.push("/");
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
          });
      }
    },
    dosave(type) {
      Dialog.confirm({
        title: type == 2 ? "开始运动" : "结束运动",
        message: "是确认" + (type == 2 ? "开始" : "结束") + "？"
      }).then(() => {
        var that = this;
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "API_setYYZT",
              Params: [that.code, type]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              Dialog({ message: "操作成功" });
              that.showInfo("");
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
          });
      });
    },
    showInfo(code) {
      var that = this;
      if (code == "") {
        that.hykh = "";
        that.yyrq = "";
        that.yysj = "";
        that.cdmc = "";
        that.fyhj = "";
        that.ksye = "";
        that.sysj = "";
        that.yyzt = "";
      } else {
        that.code = code;
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "API_getYYXX",
              Params: [code]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              that.hykh = response.data.Table[0].HYKH;
              that.yyrq = response.data.Table[0].YYRQ;
              that.yysj = response.data.Table[0].YYSJ;
              that.cdmc = response.data.Table[0].CDMC;
              that.fyhj = response.data.Table[0].FYHJ;
              that.ksye = response.data.Table[0].KSYE;
              that.sysj = response.data.Table[0].SYSJ;
              that.yyzt = response.data.Table[0].YYZT;
            } else {
              Dialog({ message: response.data.msg });
              that.finished = true;
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
            that.finished = true;
          });
      }
    },
    scan() {
      if (this.sys) {
        wx.scanQRCode({
          needResult: 1,
          desc: "请扫描二维码",
          success: r => {
            this.showInfo(r.resultStr);
          }
        });
      }
    }
  },
  created: function() {
    this.finished = false;
    let url = window.location.href.split("#")[0];
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS && window.sessionStorage.getItem("firstEntryUrl")) {
      url = window.sessionStorage.getItem("firstEntryUrl").split("#")[0];
    }
    var that = this;
    that.$axios
      .get("/api/WeChatConfig", {
        params: {
          url: url
        }
      })
      .then(res => {
        let data = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名，见附录1
          jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表，所有JS接口2
        });
        that.sys = true;
        wx.error(r => {
          that.sys = false;
          Dialog({ message: r.errMsg });
        });
        that.finished = true;
      })
      .catch(error => {
        Dialog({ message: error });
        that.finished = true;
      });
  }
};
</script>